@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

@layer components {
  /*Buttons*/
  .btn {
    @apply inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50;
  }

  .btn-primary {
    @apply bg-trames-orange bg-opacity-90 text-white hover:bg-opacity-100 focus:ring-trames-orange;
  }

  .btn-danger {
    @apply bg-trames-red bg-opacity-90 text-white hover:bg-opacity-100 focus:ring-trames-red;
  }

  .btn-primary-invert {
    @apply bg-trames-orange bg-opacity-20 text-trames-orange hover:bg-opacity-40 focus:ring-trames-orange;
  }

  .btn-large {
    @apply py-4 text-2xl font-bold;
  }

  .choice-item-list {
    @apply flex flex-col space-y-4 self-stretch;
  }

  .choice-item {
    @apply relative flex flex w-full cursor-pointer items-center justify-between rounded-2xl border bg-white p-5;
  }

  .choice-item-active {
    @apply border-trames-orange bg-trames-orange bg-opacity-10;
  }

  /*Box*/
  .box-container {
    @apply rounded-3xl bg-white p-4;
  }

  .box-title {
    @apply text-xl font-bold;
  }

  /*Badges*/
  .badge {
    @apply inline-flex items-center rounded-full px-2.5 py-0.5 font-bold;
  }

  .badge-primary {
    @apply bg-trames-orange text-white;
  }

  .badge-primary-invert {
    @apply bg-trames-orange bg-opacity-20 text-trames-orange;
  }

  .badge-success {
    @apply bg-trames-green text-white;
  }

  .badge-success-invert {
    @apply bg-trames-green bg-opacity-20 text-trames-green;
  }

  .main-title {
    @apply text-4xl font-bold text-gray-900;
  }
}

.react-transform-wrapper,
.react-transform-wrapper .react-transform-component,
.react-transform-wrapper .react-pdf__Document,
.react-transform-wrapper .react-pdf__Page,
.react-transform-wrapper .react-pdf__Page__canvas,
.react-transform-wrapper .react-pdf__Page__textContent {
  height: 100% !important;
  width: auto !important;
}

.flatpickr-months .flatpickr-month, .flatpickr-weekdays, .flatpickr-current-month .flatpickr-monthDropdown-months, span.flatpickr-weekday, .flatpickr-day.selected {
    color: white !important;
    background: #f6a925 !important;
    fill: #f6a925 !important;
    border-color: #f6a925 !important;
}

.flatpickr-wrapper {
    width: 100%
}

.flatpickr-time.hasSeconds .numInputWrapper:first-child, .numInputWrapper:first-child + span, .flatpickr-time .flatpickr-am-pm{
    display: none
}
input[type="range"] {
  -webkit-appearance: none;
  border-color: #d1d5db;
  border-width: 1px;
}

input[type="range"]::-webkit-slider-runnable-track {
  border-radius: 0.5rem;
  height: 1.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -8px; /* Centers thumb on the track */
  background-color: #f6a925;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;
}